import axios from 'axios'

export default{
    state:{

    },
    getters:{

    },
    mutations:{

    },
    actions:{
        processData(){
            try {
                var config = {
                    method: 'get',
                    url: 'https://zbgvhwwampcbxveohmjj.supabase.co/functions/v1/arsai/process-data',
                    // url: 'http://127.0.0.1:54321/functions/v1/arsai/process-data',
                    headers: { 
                       "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpiZ3Zod3dhbXBjYnh2ZW9obWpqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE5NzM0MjMsImV4cCI6MjAxNzU0OTQyM30.v-LU012HGCtLNve0ul6FXmowNquaJt8rBfdkI-wk8cg"
                    //    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0"
                    }
                 };
                 
                 axios(config)
                 .then(function (response) {
                    return response
                 })
                 .catch(function (error) {
                    console.log(error);
                    return error
                 });            
                
            } catch (error) {
                console.log(error)
                return error
                
            }

        }
   

    }
}