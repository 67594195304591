<template>
  <div>
    <v-card tile elevation="0" color="cardBackground">
      <v-layout>
        <v-toolbar flat color="grey-lighten-2">
          <v-sheet width="20vw" color="transparent" class="mx-3">
            <v-text-field
              placeholder="Search"
              hide-details
              density="compact"
              variant="outlined"
              append-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-sheet>
          <span class="font-weight-medium"
            >{{ qnaPairs.length }} Q&A Pairs</span
          >

          <template v-slot:append>
            <v-btn
              icon="mdi-sync"
              variant="plain"
              :ripple="false"
              @click="$store.dispatch('fetchQna')"
            ></v-btn>
            <v-btn
              border
              color="primary"
              prepend-icon="mdi-plus"
              class="text-none mx-1"
              variant="flat"
              @click="addNewQna"
              >Add Q&A</v-btn
            >
            <v-btn
              border
              color="primary"
              prepend-icon="mdi-content-save"
              class="text-none mx-1"
              variant="flat"
              @click="saveQna"
              >Save</v-btn
            >
            <v-btn
              border
              color="primary"
              prepend-icon="mdi-brain"
              
              class="text-none mx-1"
              variant="flat"
              @click="$store.dispatch('processData')"
              >Train</v-btn
            >
            <v-btn icon="mdi-dots-vertical" variant="plain" :ripple="false">
              <v-icon>mdi-dots-vertical</v-icon>
              <v-menu activator="parent">
                <v-list density="compact">
                  <v-list-item                    
                  >
                    <v-list-item-title @click="refreshEmbeddings">Refresh Embeddings</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </template>
        </v-toolbar>
      </v-layout>
    </v-card>
    <div v-if="qnaPairs.length === 0" class="d-flex justify-center my-10">
      Nothing to show. Click on
      <v-btn
        variant="text"
        :ripple="false"
        density="compact"
        class="mx-n2"
        @click="addNewQna"
        color="primary"
        >+ Add Q&A</v-btn
      >
      get started.
    </div>
    <v-card v-else tile elevation="0" color="cardBackground">
      <v-row class="text-subtitle-2 mt-2 mb-n3 mr-10">
        <v-col align="center">Questions</v-col>
        <v-col align="center">Answer</v-col>
        <v-col align="center">Action & Category</v-col>
        <v-col align="center">Action Details</v-col>
      </v-row>
      <v-infinite-scroll height="72vh" @load="onLoad">
        <v-divider></v-divider>
        <template
          v-for="(item, index) in qnaPairs.slice().reverse()"
          :key="index"
        >
          <v-container fluid class="pa-0 ma-0">
            <QnaPairAddSectionCompVue
              :key="index"
              :questions="item.questions"
              :answer="item.answer"
              :category="item.category"
              :action="item.action"
              :action_details="item.action_details"
              :isEdited="item.isEdited"
              :obj="item"
              @update-data="updateData(index, $event)"
              @delete-record="deleteRecord(index, $event)"
            />
          </v-container>
        </template>
        <template v-slot:empty> </template>
      </v-infinite-scroll>
    </v-card>
    <!-- <fileSelectorDialogCompVue/> -->
  </div>
</template>

<script>
import QnaPairAddSectionCompVue from "@/components/knowledgebaseComponents/QnaPairAddSectionComp.vue";
import randomize from "randomatic";
import { mapGetters } from "vuex";
// import fileSelectorDialogCompVue from '@/components/knowledgebaseComponents/fileSelectorDialogComp.vue';

export default {
  name: "KnowledgebaseView",
  components: {
    QnaPairAddSectionCompVue,
    // fileSelectorDialogCompVue
  },
  data() {
    return {
      // qnaPairs: [],
    };
  },
  computed: {
    ...mapGetters(["getQnaPairs"]),
    qnaPairs: {
      get() {
        return this.getQnaPairs;
      },
      set(val) {
        this.$store.commit("setQnaPair", val);
      },
    },
  },
  methods: {
    addNewQna() {
      this.qnaPairs.push({
        qna_id: randomize("Aa0", 8),
        questions: [""],
        answer: null,
        category: null,
        action: { label: null, value: null },
        action_details: {},
        isEdited: true,
        meta: null,
        updated_at: null,
      });
      // console.log(this.qnaPairs[this.qnaPairs.length - 1]);
    },
    updateData(reversedIndex, updatedData) {
      const actualIndex = this.qnaPairs.length - 1 - reversedIndex;
      // console.log(updatedData);
      Object.assign(this.qnaPairs[actualIndex], updatedData);
      this.qnaPairs[actualIndex].isEdited = true;
    },
    deleteRecord(reversedIndex, data) {
      const actualIndex = this.qnaPairs.length - 1 - reversedIndex;
      // console.log(actualIndex);
      console.log(data);
      if ("id" in data) {
        this.$store.dispatch("deleteRecord", data.id);
      }

      this.qnaPairs.splice(actualIndex, 1);
    },
    saveQna() {
      this.$store
        .dispatch("addQna", this.qnaPairs)
        .then((response) => {
          alert(response);
        })
        .catch((error) => {
          alert(error);
        });
    },
    onLoad({ done }) {
      done("empty");
    },
    refreshEmbeddings() {
      this.$store.dispatch("updateExistingRecords", this.getQnaPairs);
    },
  },
  mounted() {
    if (this.getQnaPairs.length === 0) {
       this.$store.dispatch("fetchQna");
    }
  },
};
</script>