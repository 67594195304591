<template>
  <v-card tile flat :rounded="0" elevation="0" color="#303030" height="30vh">
    
    <v-container fluid class="pa-0 fill-height">
      <video
        autoplay
        muted
        loop
        contain
        width="100%"
        src="https://zbgvhwwampcbxveohmjj.supabase.co/storage/v1/object/sign/taco/JarCake_Horizontal_1920x1080_Ver1.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ0YWNvL0phckNha2VfSG9yaXpvbnRhbF8xOTIweDEwODBfVmVyMS5tcDQiLCJpYXQiOjE3MDg3MDM3NjksImV4cCI6MTc0MDIzOTc2OX0.CMnEBngONKegNWrlzaFFEiq7PpuL_e3xHE0GaFDL830&t=2024-02-23T15%3A56%3A09.232Z"        
      ></video>
          <div class="text-h6 text-grey">{{responseTime}}</div>
    </v-container>
    
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "KioskFooter",
  computed:{
      ...mapGetters(['getResponseTime']),
      responseTime(){
        return Math.ceil(this.getResponseTime)
      }
    }
};
</script>
