<template>
  <v-footer class="bg-grey-lighten-1" max-height="2vh">
    <v-row justify="center" no-gutters>      
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
    name: 'FooterComp'
}
</script>