import { createClient } from '@supabase/supabase-js'


export default {
    state: {
        supabase: null,
        supabaseUrl: process.env.VUE_APP_SUPERBASE_URL_LC_CHATBOT,
        supabaseKey: process.env.VUE_APP_SUPERBASE_API_KEY,
        recordCount: 0,
        vectorCount: '',
        qnaPairs: [],
    },
    getters: {
        supabase: state => state.supabase,
        getQnaPairs: state => state.qnaPairs,
        getRecordCount: state => state.recordCount,
        getVectorCount: state => state.vectorCount
    },
    mutations: {
        setQnaPair(state, pair) {
            state.qnaPairs.push(pair);
        },
        setQnaPairsArray(state, arr) {
            state.qnaPairs = arr
            console.log(arr)
        },
        setRecordCount(state, count) {
            state.recordCount = count
        },
        setVectorCount(state, count) {
            state.vectorCount = count * 1536

        }

    },
    actions: {
        async createClient({ state, commit }) {
            state.supabase = await createClient(state.supabaseUrl, state.supabaseKey)
        },
        formatItem({ state }, item) {
            return new Promise((res, rej) => {                
                let formattedString = "Questions:\n";

                // Add each question to the string
                item.questions.forEach(question => {
                    formattedString += question + "\n";
                });

                // Add the answer, category, and action to the string
                formattedString += "answer: " + item.answer + "\n";
                formattedString += "category: " + item.category.name + "\n";
                formattedString += "action: " + item.action.label + "\n";                
                formattedString += `action Details:${item.action_details} `
                console.log(formattedString)

                res(formattedString);

            })

        },

        async addQna({ state, dispatch }) {
            return new Promise((response, reject) => {
                let newRecords = [];
                let updatingRecords = [];
                state.qnaPairs.forEach(item => {
                    if (item.isEdited && item.questions[0] !== '') {
                        let temp = { ...item };
                        // temp.action = JSON.stringify(item.action);
                        // temp.category = JSON.stringify(item.category);
                        // temp.meta = JSON.stringify(item.meta);
                        temp.updated_at = new Date().toISOString();
                        // temp.questions = JSON.stringify(item.questions.reduce((obj, item, index) => {
                        //     obj[index] = item;
                        //     return obj;
                        // }, {}));
                        let formattedString = "Questions:\n";

                        console.log("ITEMMMM::: ", item)

                        // Add each question to the string
                        item.questions.forEach(question => {
                            formattedString += question + "\n";
                        });

                        // Add the answer, category, and action to the string
                        formattedString += "answer: " + item.answer + "\n";
                        formattedString += "category: " + item.category.name + "\n";
                        formattedString += "action: " + item.action.label + "\n";
                        formattedString += "actionDetail: " + item.action.label + "";   
                        if('vidName' in item.action_details){
                            formattedString += "vidName: " + item.action_details.vidName + "\n";   
                        }
                        if('imgName' in item.action_details){
                            formattedString += "imgName: " + item.action_details.imgName + "\n";   
                        }
                        formattedString += `---- \n`
                        
                        // formattedString += `qna_id: ${ item.qna_id} \n ---- \n`
                        console.log(formattedString)

                        temp.embeddingTxt = formattedString
                        temp.isEdited = false;
                        item.isEdited = false;
                        if ('id' in item) {
                            updatingRecords.push(temp)
                        }
                        else {
                            newRecords.push(temp)
                        }
                    }
                })
                if (newRecords.length !== 0) {
                    dispatch('addNewRecords', newRecords)
                }
                if (updatingRecords !== 0) {
                    dispatch('updateExistingRecords', updatingRecords)
                }

            })
        },
        addNewRecords({ state }, items) {
            return new Promise((response, reject) => {
                state.supabase
                    .from('kb')
                    .insert(items)
                    .then(result => {
                        if (result.error === null) {
                            response('Added Successfully')
                        }
                        else {
                            reject(result.error)
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        updateExistingRecords({ state }, items) {
            return new Promise((response, reject) => {

                items.forEach(item => {
                    let formattedString = "Questions:\n";

                        console.log("ITEMMMM::: ", item)

                        // Add each question to the string
                        item.questions.forEach(question => {
                            formattedString += question + "\n";
                        });

                        // Add the answer, category, and action to the string
                        formattedString += "answer: " + item.answer + "\n";
                        formattedString += "category: " + item.category.name + "\n";
                        formattedString += "action: " + item.action.value + "\n";
                        formattedString += "action_details: ";   
                        if('vidName' in item.action_details){
                            formattedString += item.action_details.vidName + "\n";   
                        } else
                        if('imgName' in item.action_details){
                            formattedString +=  item.action_details.imgName + "\n";   
                        }
                        else{
                            formattedString += "null \n";   
                        }
                        // formattedString += `---- \n`
                        formattedString += `qna_id: ${ item.qna_id} \n ---- \n`
                        item.embeddingTxt = formattedString                        
                        
                        
                        // console.log(formattedString)
                    state.supabase
                        .from('kb')
                        .update(item)
                        .eq('id', item.id)
                        .then(result => {
                            if (result.error === null) {
                                console.log('updated successfully')
                                response(`Update Successfully`)
                            }
                            else {
                                console.log(result.error)
                                reject(result.error)
                            }
                        })
                })
            })
        },
        uploadFile({ state }, fileObj) {
            return new Promise((response, reject) => {
                let filePath;
                if ('imgName' in fileObj) {
                    filePath = `assets/${fileObj.imgName}`
                }
                else {
                    filePath = `assets/${fileObj.vidName}`
                }
                state.supabase.storage
                    .from('taco')
                    .upload(filePath, fileObj.file[0], { upsert: true, })
                    .then(result => {
                        if (result.error === null) {
                            response(`File Uploaded: ${result.data}`)

                        }
                        else {
                            reject(result.error)
                        }
                    })
            })
        },
        fetchQna({ state, commit }) {
            return new Promise((response, reject) => {
                state.qnaPairs = []
                state.supabase
                    .from('kb')
                    .select()
                    .then(result => {
                        if (result.data !== null) {
                            // result.data.forEach(item => {
                            //     console.log(item)
                            //     // item.questions = Object.values(JSON.parse(item.questions))
                            //     // item.questions = item.questions
                            //     // item.action = item.action;
                            //     // item.category = item.category;
                            //     commit("setQnaPair", item)
                            //     response('Fetched Successfully')
                            // })
                            // console.log(result.data)
                            state.qnaPairs = result.data
                                response('Fetched Successfully')
                        }
                        else {
                            reject(result.error)
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        deleteRecord({ state }, id) {
            return new Promise((response, reject) => {
                state.supabase
                    .from('kb')
                    .delete()
                    .eq('id', id)
                    .then(result => {
                        if (result.error) {
                            reject(result.error)
                        }
                        else {
                            response('Deleted Successfully')
                        }
                    })
            })
        },
        performRecordCount({ state, commit }) {
            state.supabase
                .from('kb')
                .select('*', { count: 'exact' })
                .then((result) => {
                    commit('setRecordCount', result.count)
                })

            state.supabase
                .from('documents')
                .select('*', { count: 'exact' })
                .then((result) => {
                    commit('setVectorCount', result.count)
                })
        }
    }
}