<template>
  
    <v-btn
      icon
      @click="
        
        changeTheme();
      "
    >
    <v-fade-transition leave-absolute>
      <v-icon color="black" v-if="getCurrentTheme === 'myCustomLightTheme'">
        mdi-weather-night
      </v-icon>
      <v-icon v-else>
        mdi-white-balance-sunny
      </v-icon>
      </v-fade-transition>
    </v-btn>
  
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ThemeToggleButton",
  computed: {
    ...mapGetters(["getCurrentTheme"]),
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.global.name = this.$vuetify.theme.current.dark ? "myCustomLightTheme": "myCustomDarkTheme";  
      this.$store.dispatch("changeTheme");              
    },
  },  
  beforeMount(){
    if(localStorage.getItem('currentTheme')){
    this.$vuetify.theme.global.name = localStorage.getItem('currentTheme')
    this.$store.commit('setCurrentTheme', localStorage.getItem('currentTheme'))
    }

  }
};
</script>