export default {
    state: {
        filesObjArr: [],
        isFileUploading: false,
    },
    getters: {
        getFilesObjArr: state => state.filesObjArr,
        getIsFileUploading: state => state.isFileUploading
    },
    mutations: {
        setFilesObjArr(state, arr) {
            state.filesObjArr = arr
        },
        setIsFileUploading(state, status) {
            state.isFileUploading = status
        }
    },
    actions: {
        refreshFileList({ state, dispatch }) {
            state.filesObjArr = [];
            dispatch('fetchAllFiles')
        },
        fetchAllFiles({ getters, commit }) {
            return new Promise((resolve, reject) => {
                getters.supabase
                    .storage
                    .from('taco')
                    .list()
                    .then(result => {
                        if (result.error) {
                            console.error('Error fetching files:', result.error);
                            reject(result.error);
                        } else {
                            commit('setFilesObjArr', result.data);
                            resolve('Files fetched successfully');
                        }
                    })
                    .catch(error => {
                        console.error('Error during fetch:', error);
                        reject(error);
                    });
            });
        },
        uploadFiles({ getters, dispatch, commit }, files) {
            return new Promise((response, reject) => {
                console.log(files)
                commit('setIsFileUploading', true)
                files.forEach(file => {
                    getters.supabase.storage
                        .from('taco')
                        .upload(file.name, file, { upsert: true, })
                        .then(result => {
                            if (result.error) {
                                console.error(result.error)
                                commit('setIsFileUploading', false)
                                reject(result.error)
                            }
                        })
                        .catch(error => {
                            commit('setIsFileUploading', false)
                            
                            reject(error)
                        })
                })
                setTimeout(() => { 
                    dispatch('refreshFileList') ;
                commit('setIsFileUploading', false);
                response('File uploaded successfully');}, 1000)
                
            })
        },
        generateSignedURL({ getters }, fileName) {
            return new Promise((response, reject) => {
                getters.supabase.storage
                    .from('taco')
                    .createSignedUrl(fileName,  699999 )
                    .then(result => {
                        if (result.error) {
                            console.error('Error generating URL:', result.error)
                            reject(result.error)
                        }
                        else {
                            response(result.data.signedUrl)
                        }
                    })
                    .catch(error => {
                        console.error('Error generating URL:', error)
                        reject(error)
                    })

            })
        },
        deleteFiles({ getters, dispatch }, fileNames) {
            return new Promise((response, reject) => {
                if(!Array.isArray(fileNames)){
                    fileNames = [fileNames]
                }
                getters.supabase.storage
                    .from('taco')
                    .remove(fileNames)
                    .then(result => {
                        if (result.error) {
                            console.error('Error Deleting File:', result.error)
                            reject(result.error)
                        }
                        else {
                            dispatch('refreshFileList')
                            response("Deleted successfully")
                        }
                    })
                    .catch(error => {
                        console.error('Error Deleting File:', error)
                        reject(error)
                    })
            })

        }


    }
}