import SitePal from "sitepal-vue";

export default {
    state: {
        avatarStatus: 'idle',
        defaultAvatarGreetingText: '',
        avatarVoice: 3,
        avatarLang: 1,
        avatarEngine: 3,
    },
    getters: {
        getDefaultAvatarGreetingText: state => state.defaultAvatarGreetingText,
        getAvatarStatus: state => state.avatarStatus
    },
    mutations: {
        getDefaultAvatarGreetingText(state, text) {
            state.defaultAvatarGreetingText = text
        },
        setAvatarStatus(state, status) {
            state.avatarStatus = status;
            console.log(`Avatar Status Updated -> ${state.avatarStatus}`)
        }
    },
    actions: {
        avatarSpeak({ state, commit}, txt) {
            if(state.avatarStatus === 'listening') return
            SitePal.stopSpeech();
            SitePal.sayText(txt, state.avatarVoice, state.avatarLang, state.avatarEngine);
            commit('setTextBubbleContent', txt)
        },
        avatarStopSpeaking() {
            SitePal.stopSpeech();
        },
        discardAIResponse({state}){            
            if(state.avatarStatus === 'thinking'){
                // this flag will be checked on AI controller and speech rec
                state.avatarStatus = 'idle';                
            }
        },
        sitepalListners({commit}) {
            window.vh_sceneLoaded = () => {
                console.log('scene loaded')
            }
            window.vh_audioProgress = (percent) => {
                console.log(`vh_audioProgress(): ${percent}`)
            }
            window.vh_talkStarted = () => {
                console.log(`vh_talkStarted()`)
                commit('setAvatarStatus', 'speaking')
            }
            window.vh_talkEnded = () => {
                console.log(`vh_talkEnded`)
                commit('setAvatarStatus', 'idle')
            }

        }
    }
}