<template>
<SitePal          
          :embed="embedding"
        />
    
</template>

<script>
import SitePal from "sitepal-vue";
export default {
    name: "AvatarComp",
    data(){
      return{
        width: 1080,
        height: 1920,
        embedding: null
      }
    },
    components:{
        SitePal
    },
    methods:{
      
    },
    beforeMount(){
      this.embedding = `7295865,${window.innerHeight /1.8},${window.innerWidth / 1.8},"",1,0,2766950,0,1,0,"mh1vemtYa0lgHoHE2fM1veDemSUasbgq",0,0`

    },    
}
</script>