<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:prepend>
        <v-icon>{{ $route.meta.icon }}</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsComp",
  data() {
    return {
      rou: "",
      items: [],
    };
  },
  watch: {
    $route() {
      this.items = [
        {
          title: this.$route.name,
          disabled: false,
          href: this.$route.path,
          icon: this.$route.meta.icon,
        },
      ];
    },
  },
  mounted() {},
};
</script>