<template>
    <v-card
      class="d-flex align-center"
      tile
      elevation="0"
      height="15vh"
      color="transparent"
    >
      <v-img height="68%" contain src="@/assets/logo.png"></v-img>
      
    </v-card>     
     
</template>

<script>

export default {
    name: 'KioskHeader',
    
}
</script>