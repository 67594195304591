<template>
  <v-dialog
    scrim="#ef8402"
    v-model="showImageDialog"
    width="65%"
    style="z-index: 9999"
    persistent
  >
    <v-card class="d-flex justify-center align-center px-3 pb-3">
      <v-card-title class="text-decoration-underline text-white">Image</v-card-title>
      <v-progress-circular
      :rotate="360"
      :size="36"
      :width="6"
      class="ma-2"      
      :model-value="closePercentage"
      style="position: absolute; top: 0; right: 0"
      color="red"
    >
      <v-icon
        @click="showImageDialog = !showImageDialog"
        size="x-large"
        color="red"        
        icon="mdi-close-circle"
      ></v-icon>
    </v-progress-circular>
      
      <v-img
        width="100%"
        contain
        :src="getImageDialogSrc"
      ></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ImageViewewComp",
  data() {
    return {
      showDialog: false,
      closeInterval: null,
      closePercentage: 0,
    };
  },
  watch:{
    getAvatarStatus(newVal, oldVal){
      if(oldVal === 'speaking' && this.showImageDialog){
        this.closeInterval = setInterval(() => {
          if(this.closePercentage === 100){            
            clearInterval(this.closeInterval)
            this.showImageDialog = false
            this.closePercentage = 0;
          }
          this.closePercentage += 2;
        }, 250)      
      }
    }
  },
  computed:{
    ...mapGetters(['getShowImageDialog','getImageDialogSrc', 'getAvatarStatus']),
    showImageDialog:{
      get(){
        return this.getShowImageDialog
      },
      set(val){
        clearInterval(this.closeInterval)
        this.$store.commit('setShowImageDialog', false)
        
      }
    }
    
  },
  
};
</script>

<style  >
.v-overlay__scrim {
  opacity: 0.4;
}
</style>
