<template>
<div>
  <v-card elevation="0" color="grey-lighten-3" class="pa-5 my-2">
    <v-row align="start">
      <v-col cols="2">
        <v-card elevation="0" color="primary">
          <v-card-title align="center">{{getRecordCount}}</v-card-title>        
          <v-divider></v-divider>
          <v-card-title align="center"> <v-icon left>mdi-database</v-icon> Q&A Pairs</v-card-title>        
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card elevation="0" color="primary">
          <v-card-title align="center">{{getVectorCount}} <span class="text-caption"></span> </v-card-title>        
          <v-divider></v-divider>
          <v-card-title align="center"> <v-icon>mdi-vector-polygon</v-icon> Vectors</v-card-title>        
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card elevation="0" color="primary">
          <v-card-title align="center">120</v-card-title>        
          <v-divider></v-divider>
          <v-card-title align="center"> <v-icon>mdi-set-left-right</v-icon> Sessions</v-card-title>        
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  <!-- <v-card elevation="0" color="grey-lighten-3" class="pa-5 my-2">
    <v-row align="start">
      <v-col cols="2">
        <v-card elevation="0" class="d-flex justify-center" >          
          <v-card-title align="center"> <v-icon left>mdi-human-greeting</v-icon>  <br> Greeting Text</v-card-title>                                      
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-textarea rows="2" hide-details="" height="10vw"></v-textarea>
      </v-col>      
    </v-row>
  </v-card> -->

</div>
  
  
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "DashboardView",
  computed:{
    ...mapGetters(['getRecordCount', 'getVectorCount'])
  },
  mounted(){
    this.$store.dispatch('performRecordCount')        
  }
};
</script>