<template>
  <div class="glow-border mt-n7" ref="anBtn">
    <v-card
      width="100%"
      height="100%"
      color="#ff7043"
      class="statusButton d-flex justify-center align-center"
      @click="buttonHandler"
      :ripple="false"
    >
      <!-- show microphone button -->
      <v-fade-transition leave-absolute hide-on-leave>
        <div v-if="getAvatarStatus === 'idle'">
          <!-- <v-icon color="white" size="2vh">mdi-microphone</v-icon> -->
          <!-- <br /> -->
          <div style="width: 60px; margin-bottom: -18px" >
              <LottieAnimation
                :animation-data="mic"
                :auto-play="true"
                :loop="true"
                :speed="1"
                ref="mic_animation"
              >
              </LottieAnimation>
            </div>
          
            TAP TO <br />
            TALK
          
        </div>
      </v-fade-transition>

      <!-- show listening animation -->
      <v-fade-transition leave-absolute hide-on-leave>
        <div v-if="getAvatarStatus === 'listening'">
          <waveformAnimationVue></waveformAnimationVue>
        </div>
      </v-fade-transition>

      <!-- show thinking animation -->
      <v-fade-transition leave-absolute hide-on-leave>
        <div v-if="getAvatarStatus === 'thinking'">
          <div class="d-flex justify-center align-center">
            <div style="width: 60px">
              <LottieAnimation
                :animation-data="coffee"
                :auto-play="true"
                :loop="true"
                :speed="1"
                ref="anim"
              >
              </LottieAnimation>
            </div>

            THINKING
            <div class="l-1 letter">.</div>
            <div class="l-2 letter">.</div>
            <div class="l-3 letter">.</div>
          </div>
        </div>
      </v-fade-transition>

      <!-- show speaking animation -->
      <v-fade-transition leave-absolute hide-on-leave>
        <div v-if="getAvatarStatus === 'speaking'">
          <div class="d-flex justify-center align-center">
            <div style="width: 35px; margin-right: 5px">
              <LottieAnimation
                :animation-data="speaker"
                :auto-play="true"
                :loop="true"
                :speed="1"
                ref="anim"
              >
              </LottieAnimation>
            </div>

            SPEAKING
            <div class="l-1 letter">.</div>
            <div class="l-2 letter">.</div>
            <div class="l-3 letter">.</div>
          </div>
        </div>
      </v-fade-transition>
    </v-card>
  </div>
</template>

<script setup>
import { LottieAnimation } from "lottie-web-vue";
import coffee from "@/assets/coffee_loading_white.json";
import mic from '@/assets/mic_animation.json'
import speaker from '@/assets/speaker.json'
</script>

<script>
import { mapGetters } from "vuex";
import waveformAnimationVue from "./waveformAnimation.vue";

export default {
  name: "morphedButton",
  components: {
    waveformAnimationVue,
  },
  computed: {
    ...mapGetters(["getAvatarStatus"]),
  },
  watch: {
    getAvatarStatus(status) {
      if (
        status === "listening" ||
        status === "speaking" ||
        status === "thinking"
      ) {
        this.animationHandler(true);
      } else {
        this.animationHandler(false);
      }
    },
  },

  data() {
    return {
      showListening: false,
    };
  },
  methods: {
    buttonHandler() {
      // idle not-listening not-speaking
      // -> allow click-> start speech recognition
      if (this.getAvatarStatus === "idle") {
        this.$store.dispatch("startSpeechRecognition");
      }

      // listneing
      // -> do not allow to start speeech recognition
      // -> show listening animation
      // -> display interm transcript
      if (this.getAvatarStatus === "listening") {
        this.$store.dispatch("abortSpeechRecognition");
      }

      // thinking
      // -> show thinking animation
      // -> do not allow to start speech recognition
      if (this.getAvatarStatus === "thinking") {
        this.$store.dispatch("discardAIResponse");
      }

      // speaking
      // -> show speaking animation
      // -> show stop speaking icon
      // -> allow clicl -> stop speech
      if (this.getAvatarStatus === "speaking") {
        this.$store.dispatch("avatarStopSpeaking");
      }

      // not-speaking
    },
    animationHandler(expand) {
      if (expand) {
        this.$refs.anBtn.style.animation = "morphforward 0.7s forwards";
      } else {
        this.$refs.anBtn.style.animation = "morphreverse 0.7s forwards";
      }
    },
  },
  mounted() {
    // this.animationHandler(true);
    // this.$store.commit('setAvatarStatus', 'speaking')
    
  },
};
</script>

// dot animation styling
<style>
@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.letter {
  animation-name: loadingF;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.letter {
  float: right;
  font-size: 1.5em;
  color: #fff;
  margin-top: -6px;
}
.l-1 {
  animation-delay: 0.72s;
}
.l-2 {
  animation-delay: 0.6s;
}
.l-3 {
  animation-delay: 0.48s;
}
</style>


// glow and button configuration/animation
<style>
.statusButton {
  border-radius: 5vh;
  border: 3.35px solid #fff;
  font-size: 0.9em;
}

.glow-border {
  position: relative;
  z-index: 0;
  width: 5vh;
  height: 5vh;
  border-radius: 5vh;
}

@keyframes morphforward {
  0% {
    width: 5vh;
    height: 5vh;
    border-radius: 5vh;
  }
  100% {
    width: 10vh;
    height: 3.2vh;
    border-radius: 5vh;
    opacity: 0.95;
  }
}

@keyframes morphreverse {
  0% {
    width: 10vh;
    height: 3.2vh;
    border-radius: 5vh;
    opacity: 0.95;
  }
  100% {
    width: 5vh;
    height: 5vh;
    border-radius: 5vh;
  }
}

.glow-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    85deg,
    #ffff,
    #424242,
    #ffff,
    #424242,
    #ffff,
    #ffff,
    #424242,
    #ffff
  );
  /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00ab, #ff0000); */
  background-size: 500%;
  border-radius: inherit; /* Inherit border-radius from the button */
  filter: blur(20px); /* Adjust the blur to create a border-like effect */
  margin: -4px; /* Pull the pseudo-element outwards */
  animation: glowing 50s linear infinite;
  opacity: 0.7;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
