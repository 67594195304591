<template>
  <v-card
    class="ma-3 pa-2 pr-0 d-flex align-center"
    :class="isEdited ? 'borders' : ''"
    elevation="0"
    color="transparent"
  >
    <v-row dense>
      <v-col>
        <v-textarea
          v-for="(n, index) in localQuestions"
          :key="index"
          :append-inner-icon="
            localQuestions.length > 1 ? 'mdi-delete-outline' : ''
          "
          @click:append-inner="deleteAlternateQuestion(index)"
          placeholder="Question"
          v-model="localQuestions[index]"
          hide-details
          density="compact"
          variant="filled"          
          rows="1"
          auto-grow
          class="mb-0"
          @input="emitData"
        ></v-textarea>
        <v-btn
          :disabled="
            localQuestions[localQuestions.length - 1] === '' ? true : false
          "
          block
          color="primary"
          prepend-icon="mdi-plus"
          class="text-none mx-1"
          density="compact"
          variant="plain"
          :ripple="false"
          @click="addAlternateQuestion"
          >Add alternate phrasing</v-btn
        >
      </v-col>
      <v-col>
        <v-textarea
          placeholder="Answer"
          hide-details
          rows="1"
          auto-grow
          density="compact"
          variant="filled"
          v-model="localAnswer"
          @input="emitData"
        ></v-textarea>
      </v-col>
      <v-col>
        <v-combobox
          :items="getCategoryList"
          label="category"
          item-title="name"
          item-value="categoryId"
          placeholder="Category"
          density="compact"
          hide-details=""
          variant="filled"
          class="mb-2"
          v-model="localCategory"
          @input="emitData"
          @update:modelValue="emitData()"
        >
        </v-combobox>
        <v-combobox
          :items="actionList"                    
          label="Action"
          item-title="label"
          item-value="value"
          placeholder="Action"
          density="compact"
          hide-details=""
          variant="filled"
          class="mt-2"
          v-model="localAction"
          @input="emitData"
          @update:modelValue="
            performAction(localAction.value);
            emitData();
          "
        >
        </v-combobox>
      </v-col>
      <v-col>
        <div v-if="localAction.value === 'navigate'">
          <v-text-field
            placeholder="URL (https://... or /...)"
            label="URL"
            hide-details            
            
            density="compact"
            variant="filled"
            v-model="localAction_Details.url"
            @input="emitData"
          ></v-text-field>
        </div>
        <div v-else-if="localAction.value === 'showimage'">          
          <v-text-field
            placeholder="URL (https://... or /...)"
            label="Image URL"
            hide-details                        
            density="compact"
            variant="filled"                             
            
            v-model="localAction_Details.url"
            @input="emitData"
          ></v-text-field>
          <v-textarea
            placeholder="Image Name"
            label="Image Name"
            hide-details
            rows="1"
            auto-grow
            density="compact"
            variant="filled"
            class="mt-2"
            @input="emitData"
            v-model="localAction_Details.imgName"
          ></v-textarea>
        </div>
        <div v-else-if="localAction.value === 'showvideo'">
          <v-text-field
            placeholder="URL (https://... or /...)"
            label="Video URL"
            hide-details
            rows="1"
            max-rows="1"            
            density="compact"
            variant="filled"
            v-model="localAction_Details.url"
            @input="emitData"
          ></v-text-field>
          <v-textarea
            placeholder="Video Name"
            label="Video Name"
            hide-details
            rows="1"
            auto-grow
            density="compact"
            variant="filled"
            class="mt-2"
            @input="emitData"
            v-model="localAction_Details.vidName"
          ></v-textarea>
        </div>
        <div v-else-if="localAction.value === 'followup'">          
          <v-textarea
            placeholder="E.g. abc,pqr"
            label="Followup"
            hide-details
            rows="1"
            auto-grow
            density="compact"
            variant="filled"
            class="mt-2"
            @input="emitData"
            v-model="localAction_Details.followup"
          ></v-textarea>
        </div>
        <div v-else-if="localAction.value === 'custom'">          
          <v-textarea
            placeholder="Accepts any custom JSON {}"
            label="Custom JSON"
            hide-details            
            auto-grow
            density="compact"
            variant="outlined"
            class="mt-2"
            @input="emitData"
            v-model="loShowVideo.custom"
          ></v-textarea>
        </div>
        <v-card-text v-else class="text-grey">No Action Details</v-card-text>
      </v-col>
    </v-row>
    <v-btn variant="plain" @click="deleteRecord" icon="mdi-trash-can-outline"></v-btn>
  </v-card>
  <v-divider></v-divider>
  <!-- <fileSelectorDialogCompVue/> -->
</template>

<script>
import { mapGetters } from 'vuex';
import fileSelectorDialogCompVue from './fileSelectorDialogComp.vue';


export default {
  name: "QnaPairAddSectionComp",
  props: {
    questions: Array,
    answer: String,
    category: Object,
    action: Object,
    action_details: Object,
    isEdited: {
      type: Boolean,
      default: false,
    },
    obj: Object
  },
  components:{
    fileSelectorDialogCompVue,     
  },
  computed:{
    ...mapGetters(['getCategoryList'])
  },
  emits: ["update-data", 'delete-record'],
  
  data() {
    return {
      selectedAction: null,
      loNavigateUrl: null,
      loShowImage:{
        imgName: null,
        file: null
      },
      loShowVideo:{
        vidName: null,
        file: null
      },
      loFollowup: null,
      loCustom: null,
      actionList: [
        { label: "Speak", value: "speak" }, //String
        { label: "Navigate", value: "navigate" }, //URL <string>, Parameters <String>
        { label: "Show Image", value: "showimage" }, //URL <string>, Upload <Blob>
        { label: "Show Video", value: "showvideo" }, //URL <string>, Upload <Blob>
        { label: "Followup", value: "followup" }, //URL <string>, Upload <Blob>
        { label: "Custom", value: "custom" }, //JSON <string>
      ],
      // categoryList: [
      //   { label: "Welcome", value: "welcome" },
      //   { label: "Menu", value: "menu" },
      //   { label: "Information", value: "information" },
      // ],
      localQuestions: this.questions,
      localAnswer: this.answer,
      localCategory: this.category,
      localAction: this.action,
      localAction_Details: this.action_details,
      localQnaObj: this.qnaObj,      
    };
  },
  watch: {    
    questions(newVal) {
      this.localQuestions = newVal;
    },
    answer(newVal) {
      this.localAnswer = newVal;
    },
    category(newVal) {
      this.localCategory = newVal;
    },
    action(newVal) {
      this.localAction = newVal;
    },
    action_details(newVal) {
      this.localAction_Details = newVal;
    },
  },
  methods: {
    addAlternateQuestion() {
      this.localQuestions.push("");
    },
    deleteAlternateQuestion(index) {
      this.localQuestions.splice(index, 1);
    },
    performAction(action) {
      this.selectedAction = action;
    },
    emitData() {
      this.$emit("update-data", {
        questions: this.localQuestions,
        answer: this.localAnswer,
        category: this.localCategory,
        action: this.localAction,
        action_details: this.localAction_Details,
      });
    },
    deleteRecord(){
      this.$emit("delete-record", this.obj)
    }
  },
  mounted(){    
    if(this.getCategoryList.length === 0){
      this.$store.dispatch('fetchCategories')
    }    
  }
};
</script>

<style >
.borders {
  border-left-width: 5px;
  border-right-width: 5px;
  border-left-style: solid;
  border-color: #66bb6a;
}
</style>