<template>
  <v-navigation-drawer color="background" :rail="!show">
    <div align="end">
      <v-btn      
        density="comfortable"
        @click="toggleDrawer"
        :block="!show"
        icon
        variant="plain"
        :ripple="false"
        >
        <v-fab-transition>
        <v-icon v-if="show">mdi-chevron-left</v-icon>
        <v-icon v-else>mdi-chevron-right</v-icon>
        </v-fab-transition>
        </v-btn>
    </div>
    <v-divider></v-divider>

    <v-list density="compact" nav>
      <v-list-item
        v-for="(item, i) in navItems"
        :key="i"
        :value="item"
        color="primary"
        :to="item.to"
      >
        <template v-slot:prepend>
          <v-icon :icon="item.icon"></v-icon>
        </template>

        <v-list-item-title>{{ item.text }} </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationPanelComp",
  data() {
    return {
      show: null,
      navItems: [
        { text: "Home", icon: "mdi-view-dashboard", value: "home", to: "/" },
        {
          text: "Knowledgebase",
          icon: "mdi-database",
          value: "knowledgebase",
          to: "/knowledgebase",
        },
        {
          text: "Categories",
          icon: "mdi-shape-plus",
          value: "categories",
          to: "/categories",
        },
        {
          text: "Files",
          icon: "mdi-folder-multiple",
          value: "files",
          to: "/files",
        },
        {
          text: "Simulator",
          icon: "mdi-mushroom",
          value: "simulator",
          to: "/simulator"
        }
        // {
        //   text: "Analytics",
        //   icon: "mdi-google-analytics",
        //   value: "analytics",
        //   to: "/analytics",
        // },
        // {
        //   text: "Kiosk Preferences",
        //   icon: "mdi-star-face",
        //   value: "kiosk-preferences",
        //   to: "/kiosk-preferences",
        // },
      ],
    };
  },
  methods:{
    toggleDrawer(){            
      this.show =  !this.show
      localStorage.setItem('navDrawerState', this.show);
      console.log(`this.show(): ${this.show}`)
      
    }
  },
  beforeMount(){
    if(localStorage.getItem('navDrawerState') === 'true'){
      this.show = true
    }
    else{
      this.show = false
    }
    
    
    
  }
  
};
</script>