import { createClient } from '@supabase/supabase-js'

export default{
    state:{
        supabase: null,
        categoriesList: []

    },
    getters:{
        getCategoryList: state => state.categoriesList
    },
    mutations:{

    },
    actions:{
        async createClient({state}){
            state.supabase = await createClient(process.env.VUE_APP_SUPERBASE_URL_LC_CHATBOT, process.env.VUE_APP_SUPERBASE_API_KEY)
        },
        async insertQnA({state}, qnaObj){            
            try {
                //insert answer and get answerId
                const {data: answerData, error: answerError} = await state.supabase
                .from('answer_tbl')
                .insert([{answer:qnaObj.answer}])
                .single();
                if(answerError) throw answerError

                // Insert Q&A and get the qnaId
                const{data: qnaData, error: qnaError} = await state.supabase
                .from('qna_tbl')
                .insert([{
                    answerId: answerData.answerId,
                    categoryId: qnaObj.categoryId,
                    actionId: qnaObj.actionId
                }])
                .single()
                if(qnaError) throw qnaError;

                //insert each question with qnaId
                for(const question in qnaObj.questions){
                    const {error: questionError} = await state.supabase
                    .from('question_tbl')
                    .insert([{
                        question: question,
                        qnaId: qnaData.qnaId
                    }])
                    if(questionError) throw questionError
                }
                return 'success'                
            } catch (error) {
                return error
            }
        },
        async fetchCategories({state}){
            try {
                const {data, error} = await state.supabase
                .from('categories_tbl')
                .select('*')
                if(error) throw error                
                state.categoriesList = data

                
                
            } catch (error) {
                return error
            }

        }


    }
}