export default{
    state:{
        showImageDialog: false,
        imageDialogSrc: null,
        showVideoDialog: false,
        videoDialogSrc: null,

    },
    getters:{
        getShowImageDialog: state => state.showImageDialog,
        getImageDialogSrc: state => state.imageDialogSrc,
        getShowVideoDialog: state => state.showVideoDialog,
        getVideoDialogSrc: state => state.videoDialogSrc
        

    },
    mutations:{
        setShowImageDialog(state, status){
            state.showImageDialog = status
        },
        setImageDialogSrc(state, src){
            state.imageDialogSrc = src
        },
        setShowVideoDialog(state, status){
            state.showVideoDialog = status
        },
        setVideoDialogSrc(state, src){
            state.videoDialogSrc = src;
        }, 
        resetDialogs(state){
            state.showImageDialog = false;
            state.showVideoDialog = false,
            state.imageDialogSrc = null;
            state.videoDialogSrc = null
        }
    },
    actions:{
        async renderResponse({dispatch,commit, getters}, aiResult){
            try {
                console.log(JSON.parse(aiResult))
                const result = JSON.parse(aiResult)
                if(result === null) throw aiResult
                switch(result.action.toLowerCase()){
                    case 'speak':
                        dispatch('avatarSpeak', result.response)
                        break;
                    case 'showimage':                                                
                        commit('setImageDialogSrc', await fetchActionDetail(getters.supabase, result.action_details, 'imgName'))
                        commit('setShowImageDialog', true)
                        dispatch('avatarSpeak', result.response)
                        break;
                    case 'showvideo':
                        commit('setVideoDialogSrc', await fetchActionDetail(getters.supabase, result.action_details, 'vidName'))
                        commit('setShowVideoDialog', true)
                        dispatch('avatarSpeak', result.response)
                        break;
                    case 'navigate':
                        break;
                    case 'followup':
                        break;
                    case 'custom':
                        break;
                    default:
                        if(result.response !== null)  
                        dispatch('avatarSpeak', result.response)

                        break;
                }                
            } catch (error) {
                console.log(error)
            }
            finally{
                console.log("AI Result: ", aiResult)
            }

            async function fetchActionDetail(supabase, name, type){
                try {
                    // const {data, error} = await supabase.from('kb').select('action_details').eq('qna_id', id).single()
                    const {data, error} = await supabase.from('kb').select(`action_details`).eq(`action_details->>${type}`, name)
                    if(error) throw error
                    console.log(data)
                    if(data.length === 0){
                        return data.action_details.url
                    }
                    else{
                        return data[0].action_details.url
                    }
                    
                    
                } catch (error) {
                    console.log(error)
                    return error
                    
                }
            }
        }
    }
}