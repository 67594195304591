<template>
  <v-card flat color="cardBackground" class="px-3" rounded="0">
    <v-card-title class="text-center text-subtitle-2"
      >File Preview</v-card-title
    >
    <v-icon
      class="ma-3"
      style="position: absolute; top: 0; right: 0"
      icon="mdi-close"
      @click="closePreview"
    ></v-icon>

    <v-img
      v-if="fileObj.metadata.mimetype.includes('image')"
      class="mx-auto"
      :height="250"
      aspect-ratio="16/9"
      contain
      lazy-src="@/assets/placeholderImg.jpg"
      max-width="500"
      max-height="500"
      :src="src"
    >
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular
            color="grey-lighten-4"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-img>

    <video
      v-if="fileObj.metadata.mimetype.includes('video')"
      width="100%"
      max-height="500"
      muted
      controls
      :src="src"
    ></video>
    <v-divider> </v-divider>
    <v-card-text class="text-h6">{{ fileObj.name }}</v-card-text>
    <v-card-text class="text-caption"
      >{{ fileObj.metadata.mimetype }} |
      {{ readableFileSize(fileObj.metadata.size) }}</v-card-text
    >
    <v-card-text class="text-caption"
      >Added on: <br />
      {{ formatTime(fileObj.created_at) }}</v-card-text
    >

    <v-card-actions>
      <v-btn color="red-darken-1" @click="deleteFile" variant="outlined">
        <v-icon left>mdi-delete</v-icon> Delete</v-btn
      >
      <v-btn @click="copyUrl" variant="outlined">
        <v-icon left>mdi-content-copy</v-icon> Copy Url</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import formatbytes from "ars-formatbytes";
import moment from "moment-timezone";

export default {
  name: "filePreviewComp",
  props: {
    src: String,
    fileObj: Object,
  },
  emits: ["close-preview"],
  methods: {
    async copyUrl() {
      console.log("COPU URL:", this.src);
      await navigator.clipboard.writeText(this.src);
    },
    readableFileSize(size) {
      return formatbytes(size);
    },
    formatTime(timestamp) {
      return moment(timestamp)
        .tz("America/New_York")
        .format("MM/DD/YYYY, hh:mm:ss A");
    },
    deleteFile() {
      this.$store.dispatch("deleteFiles", this.fileObj.name).then(() => {
        this.$emit("close-preview");
      });
    },
    closePreview() {
      this.$emit("close-preview");
    },
  },
};
</script>