<template>
  <v-app :class="getIsKioskMode ? 'bg' : ''">    
    <v-main>
      <NavigationCompVue v-if="!getIsKioskMode" />
      <v-fade-transition>
        <BreadcrumbsCompVue v-if="!getIsKioskMode" />
      </v-fade-transition>
      
        <router-view :class="!getIsKioskMode ? 'mx-5 my-1' : ''" />
      
    </v-main>
    <!-- <FooterCompVue/> -->
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import BreadcrumbsCompVue from "./components/BreadcrumbsComp.vue";
import FooterCompVue from './components/FooterComp.vue';
import NavigationCompVue from "./components/NavigationComp.vue";

export default {
  name: "App",
  components: {
    NavigationCompVue,
    BreadcrumbsCompVue,
    FooterCompVue
  },
  computed: {
    ...mapGetters(["getIsKioskMode", "getAvatarStatus"]),
  },
  watch:{
    getIsKioskMode(n){
      console.log(`::App():: getIsKioskMode() -> ${n}`)
      if(!n)this.$store.dispatch('fetchCategories')
    },
    getAvatarStatus(n){
      console.log(`::App():: getAvatarStatus() -> ${n}`)
    },    
  },
  methods: {},
  mounted() {    
    console.log(this.getIsKioskMode)
    this.$store.dispatch("checkOperationMode");
    this.$store.dispatch("createClient")
    this.$store.dispatch('sitepalListners')    
  },
};
</script>

<style>
.bg {
  background: linear-gradient(60deg, #ffffff 19%, #fffaed 45%, #fff4da 75%);
  background-size: 400% 400%;

  animation: gradient 15s ease infinite;
  /* height: 100vh; */
   
}



@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}
</style>