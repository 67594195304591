<template>
  <div class="" align="center" jusitfy="center">    
    <v-container class="iframe-wrapper">
      <div class="iframe-container">
        <iframe
          allow="camera; microphone"
          id="kioskApp"
          :src="rou"
          frameborder="1px"
        ></iframe>
        <span>{{ innWidth }}</span>
      </div>
    </v-container>
    <v-btn @click="logout()" color="error">Click to Reload the Kiosk App</v-btn>
  </div>
</template>

<script>
export default {
  name: "simulatorView",
  data() {
    return {
      innWidth: null,
      rou: null
    };
  },
  methods: {
    logout() {
      var iframe = document.getElementById("kioskApp");
      var src = iframe.src;
      iframe.src = src;
    },
  },
  mounted() {
    this.innWidth = window.innerWidth;
    this.rou = window.location.origin

  },
};
</script>

<style scoped>
.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Full height of the viewport */
  overflow: hidden;
}

.iframe-container iframe {
  width: 1080px;
  height: 1920px;
  transform: scale(0.6);
  border: none; /* Optional: removes the default border around the iframe */
}
@media screen and (max-width: 2600px) {
  .iframe-container iframe {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 2000px) {
  .iframe-container iframe {
    transform: scale(0.4);
  }
}
/* For 14" MacBook screens (or similar) */
@media screen and (max-width: 1600px) {
  .iframe-container iframe {
    transform: scale(0.35);
  }
}
</style>
