<template>
  <div>
    <v-card elevation="0" color="cardBackground"> 
        <v-toolbar flat color="grey-lighten-2">
          <v-sheet width="20vw" color="transparent" class="mx-3">
            <v-text-field
              placeholder="Search"
              hide-details
              density="compact"
              variant="outlined"
              append-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-sheet>
          

          <template v-slot:append>
                    
            <v-btn
              icon="mdi-dots-vertical"
              variant="plain"
              :ripple="false"
            ></v-btn>
          </template>
        </v-toolbar>


    </v-card>
    <v-card elevation="0">
      <v-data-table :items="getCategoryList">
        <template v-slot:[`item.created_at`]="{ item }">
              {{ formatTime(item.created_at) }}
            </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment-timezone";
export default {
  name: "CategoryView",
  computed:{
    ...mapGetters(['getCategoryList'])
  },
  data() {
    return {     
    };
  },
  methods:{
    formatTime(timestamp) {
      return moment(timestamp)
        .tz("America/New_York")
        .format("MM/DD/YYYY, hh:mm:ss A");
    },
  },
  mounted(){
    if(this.getCategoryList.length === 0){
      this.$store.dispatch('fetchCategories')
    }
  }
};
</script>