import { createRouter, createWebHistory } from 'vue-router'
import KioskView from '../views/KioskView.vue'
import DashboardView from '../views/DashboardView.vue'
import KnowledgebaseView from '../views/KnowledgebaseView.vue'
import AnalyticsView from '../views/AnalyticsView.vue'
import CategoryView from '../views/CategoryView.vue'
import FilesView from '../views/FilesView.vue'
import SimulatorView from '../views/SimulatorView.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView,
    meta:{
      title: 'Dashboard',
      icon: 'mdi-view-dashboard'
    }
  },
  {
    path: '/kioskmode',
    name: 'Kiosk Mode',
    component: KioskView,
    meta:{
      title: 'Kiosk Mode',      
      icon: ''
    }
  },
  {
    path: '/knowledgebase',
    name: 'Knowledgebase',
    component: KnowledgebaseView,
    meta:{
      title: 'Knowledgebase',
      icon: 'mdi-database'
    }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: AnalyticsView,
    meta:{
      title: 'Analytics',
      icon: 'mdi-google-analytics'
    }
  },  
  {
    path: '/categories',
    name: 'Categories',
    component: CategoryView,
    meta:{
      title: 'Categories',
      icon: 'mdi-shape-plus'
    }
  },
  {
    path: '/files',
    name: 'Files',
    component: FilesView,
    meta:{
      title: 'Files',
      icon: 'mdi-folder-multiple'
    }    
  }  ,
  {
    path: '/simulator',
    name: 'Simulator',
    component: SimulatorView,
    meta:{
      title: 'Simulator',
      icon: 'mdi-mushroom'
    } 
  } 
  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => { 
  if( to.path === "/kioskmode" && window.innerWidth > window.innerHeight){ // check if the running on desktop
    next('/');
  }
  if(to.path !== "/kioskmode" && window.innerWidth < window.innerHeight){ // check if running on kiosk
    next('/kioskmode');
  }
  else{
    next()
  }  
  document.title = `Venduni | ${to.meta.title}`;
     const link = document.querySelector("[rel='icon']")
     link.setAttribute('href',to.meta.icon)
})

export default router
