<template>
  <v-dialog
    scrim="#ef8402"
    v-model="showVideoDialog"
    width="65%"
    style="z-index: 9999"
    persistent
  >
    <v-card class="d-flex justify-center align-center px-3 pb-3">
      <v-card-title class="text-decoration-underline">Video</v-card-title>
      <v-progress-circular
        :rotate="360"
        :size="36"
        :width="6"
        class="ma-2"
        :model-value="closePercentage"
        style="position: absolute; top: 0; right: 0"
        color="red"
      >
        <v-icon
          @click="showVideoDialog = !showVideoDialog"
          size="x-large"
          color="red"
          icon="mdi-close-circle"
        ></v-icon>
      </v-progress-circular>
      <video
        width="100%"
        max-height="50vh"        
        ref="videoele"
        autoplay
        :src="getVideoDialogSrc"
        @ended="onVideoEnd"
      ></video>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VideoViewComp",
  data() {
    return {
      showDialog: false,
      closeInterval: null,
      closePercentage: 0,
      intervalController: null
    };
  },
  watch: {
    getAvatarStatus(newVal, oldVal) {
      console.log(`NewVal: ${newVal}, OldVal: ${oldVal}`)
      if (oldVal === "speaking" && this.showVideoDialog) {
        const video = this.$refs.videoele;
        if (video) {
          video.muted = false;
          this.intervalController = setInterval(()=> {
            video.volume += 0.1;            
            if(video.volume >= 0.9){              
              clearInterval(this.intervalController)
              return
            }
          }, 200)          
        }
      }
      if (newVal === "speaking" && oldVal === 'thinking' && this.showVideoDialog) {        
        const video = this.$refs.videoele;
        if (video) {
          video.volume = 0.3;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "getShowVideoDialog",
      "getVideoDialogSrc",
      "getAvatarStatus",
    ]),
    showVideoDialog: {
      get() {
        return this.getShowVideoDialog;
      },
      set(val) {
        clearInterval(this.closeInterval)
        this.$store.commit("setShowVideoDialog", false);
      },
    },
  },
  methods: {
    onVideoEnd() {
      this.closeInterval = setInterval(() => {
        if (this.closePercentage === 100) {
          clearInterval(this.closeInterval);
          this.showVideoDialog = false;
          this.closePercentage = 0;
        }
        this.closePercentage += 2;
      }, 250);
    },
  },  
};
</script>

<style  >
.v-overlay__scrim {
  opacity: 0.4;
}
</style>
