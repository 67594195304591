<template>
  <v-dialog width="60vw" max-height="70vh" v-model="showDialog">
    <v-card class="pa-2">
      <v-card-title class="text-center">Select files</v-card-title>
      <v-icon
        class="ma-3"
        style="position: absolute; top: 2; right: 0"
        icon="mdi-close"
        @click="showDialog = !showDialog"
      ></v-icon>
      <v-divider></v-divider>

      <div style="overflow-y: auto; max-height: 60vh">
        <v-row dense>
          <v-col v-for="file in getFilesObjArr" :key="file.id" cols="3">
            <v-card  >
              <!-- <v-img
                :src="card.src"
                class="align-start mx-1 mt-1"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                contain
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="white" icon="mdi-checkbox-blank-circle"></v-btn>                  
                </v-card-actions>
              </v-img> -->
              <!-- <v-card-text>
                {{file.id}}                
              </v-card-text> -->
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <span> Files Selected: 0 </span>
        <v-spacer></v-spacer>
        <v-btn @click="finish" color="primary" variant="flat" class="px-5">Finish</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "fileSelectorDialogComp",
  data() {
    return {
      showDialog: true,
      selectedFiles: [],
      cards: [],
    };
  },
  computed:{
    ...mapGetters(['getFilesObjArr'])
  },
  methods:{
    finish(){
        console.log(this.getFilesObjArr)
    }

  },
  mounted(){    
    console.log(this.getFilesObjArr.lenght)
    // if(this.getFilesObjArr.lenght < 1){        
    //     this.$store.dispatch('fetchAllFiles')
    // }

  }
};
</script>