<template>
  <div class="wave wave25 wave-active-25"></div>
  <div class="wave wave2 wave-active-2"></div>
  <div class="wave wave3 wave-active-3"></div>
  <div class="wave wave4 wave-active-4"></div>
  <div class="wave wave5 wave-active-5"></div>
  <div class="wave wave6 wave-active-6"></div>
  <div class="wave wave2 wave-active-2"></div>
  <div class="wave wave3 wave-active-3"></div>
  <div class="wave wave4 wave-active-4"></div>
  <div class="wave wave5 wave-active-5"></div>
  <div class="wave wave6 wave-active-6"></div>
  <div class="wave wave2 wave-active-2"></div>
  <div class="wave wave3 wave-active-3"></div>
  <div class="wave wave4 wave-active-4"></div>
  <div class="wave wave5 wave-active-5"></div>
  <div class="wave wave6 wave-active-6"></div>
  <div class="wave wave23 wave-active-23"></div>
  <div class="wave wave24 wave-active-24"></div>
  <div class="wave wave25 wave-active-25"></div>
</template>

<script>
export default {
  name: "waveformAnimation",
};
</script>

<style>
@keyframes active-wave-1 {
  0%,
  33% {
    transform: scaleY(0.7);
  }
  33%,
  66% {
    transform: scaleY(1.4);
  }
  66%,
  100% {
    transform: scaleY(0.7);
  }
}
@keyframes active-wave-2 {
  0%,
  33% {
    transform: scaleY(0.7);
  }
  33%,
  66% {
    transform: scaleY(1.65);
  }
  66%,
  100% {
    transform: scaleY(0.7);
  }
}
@keyframes active-wave-3 {
  0%,
  33% {
    transform: scaleY(0.8);
  }
  33%,
  66% {
    transform: scaleY(1.3);
  }
  66%,
  100% {
    transform: scaleY(0.8);
  }
}
@keyframes active-wave-4 {
  0%,
  33% {
    transform: scaleY(0.7);
  }
  33%,
  66% {
    transform: scaleY(2);
  }
  66%,
  100% {
    transform: scaleY(0.7);
  }
}

.wave {
  width: 3px;
  margin: 2px;
  background: #ffff;
  opacity: 0.7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}
.wave1 {
  height: 27px;
}
.wave2 {
  height: 32px;
}
.wave3 {
  height: 38px;
}
.wave4 {
  height: 33px;
}
.wave5 {
  height: 46px;
}
.wave6 {
  height: 40px;
}
.wave7 {
  height: 30px;
}
.wave8 {
  height: 40px;
}
.wave9 {
  height: 38px;
}
.wave10 {
  height: 30px;
}
.wave11 {
  height: 25px;
}
.wave12 {
  height: 29px;
}
.wave13 {
  height: 30px;
}
.wave14 {
  height: 24px;
}
.wave15 {
  height: 45px;
}
.wave16 {
  height: 38px;
}
.wave17 {
  height: 44px;
}
.wave18 {
  height: 52px;
}
.wave19 {
  height: 46px;
}
.wave20 {
  height: 40px;
}
.wave21 {
  height: 30px;
}
.wave22 {
  height: 40px;
}
.wave23 {
  height: 30px;
}
.wave24 {
  height: 30px;
}
.wave25 {
  height: 19px;
}

.wave-active-1 {
  animation: active-wave-1 0.45s infinite;
}
.wave-active-2 {
  animation: active-wave-4 0.55s infinite;
}
.wave-active-3 {
  animation: active-wave-3 0.48s infinite;
}
.wave-active-4 {
  animation: active-wave-4 0.6s infinite;
}
.wave-active-5 {
  animation: active-wave-1 0.51s infinite;
}
.wave-active-6 {
  animation: active-wave-2 0.58s infinite;
}
.wave-active-7 {
  animation: active-wave-3 0.45s infinite;
}
.wave-active-8 {
  animation: active-wave-4 0.7s infinite;
}
.wave-active-9 {
  animation: active-wave-4 0.65s infinite;
}
.wave-active-10 {
  animation: active-wave-2 0.59s infinite;
}
.wave-active-11 {
  animation: active-wave-1 0.64s infinite;
}
.wave-active-12 {
  animation: active-wave-2 0.55s infinite;
}
.wave-active-13 {
  animation: active-wave-3 0.46s infinite;
}
.wave-active-14 {
  animation: active-wave-4 0.39s infinite;
}
.wave-active-15 {
  animation: active-wave-1 0.79s infinite;
}
.wave-active-16 {
  animation: active-wave-2 0.6s infinite;
}
.wave-active-17 {
  animation: active-wave-3 0.75s infinite;
}
.wave-active-18 {
  animation: active-wave-2 0.55s infinite;
}
.wave-active-19 {
  animation: active-wave-4 0.65s infinite;
}
.wave-active-20 {
  animation: active-wave-1 0.56s infinite;
}
.wave-active-21 {
  animation: active-wave-3 0.42s infinite;
}
.wave-active-22 {
  animation: active-wave-2 0.68s infinite;
}
.wave-active-23 {
  animation: active-wave-4 0.74s infinite;
}
.wave-active-24 {
  animation: active-wave-3 0.53s infinite;
}
.wave-active-25 {
  animation: active-wave-2 0.45s infinite;
}
</style>