export default {
    state: {
        currentTheme: 'myCustomLightTheme',
        isKioskMode: false,
        textBubbleContent: null
    },
    getters: {
        getTextBubbleContent: state => state.textBubbleContent,
        getIsKioskMode: state => state.isKioskMode,
        getCurrentTheme: state => state.currentTheme

    },
    mutations: {
        setTextBubbleContent(state, text) {
            state.textBubbleContent = text
        },
        setIsKioskMode(state, status) {
            state.isKioskMode = status;
        },
        setCurrentTheme(state, theme) {
            state.currentTheme = theme
            localStorage.setItem('currentTheme', theme);
        }
    },
    actions: {
        changeTheme({ commit, state }) {                        
            commit('setCurrentTheme', state.currentTheme === 'myCustomLightTheme' ? 'myCustomDarkTheme' : 'myCustomLightTheme');
        },
        checkOperationMode({ commit }) {
            if (window.innerWidth < window.innerHeight) {
                commit('setIsKioskMode', true) // isKioskMode = True
            }
            else {
                commit('setIsKioskMode', false) // isKioskMode = False
            }
        },
        resetKiosk({ commit, getters, dispatch }) {
            console.log("Kiosk Reset Triggered")
            dispatch('avatarStopSpeaking');
            commit('resetConvHistory')
            commit('setAvatarStatus', 'idle')
            commit('resetDialogs')
            commit('setTextBubbleContent', getters.getDefaultAvatarGreetingText)
        },
        // welcomeUser(){
        //     try {
        //         //check if the the kiosk is already in the session

        //         //if yes
        //             // do not greet

        //         //if no
        //             // greet the user                
        //     } catch (error) {
        //         console.error(error)
        //         return error                
        //     }
            
        // }

    }
}