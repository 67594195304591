<template>
  <div>    
    <v-card
      rounded
      min-height="5vh"
      color="rgba(239,132,0,255)"
      class="px-3 pb-10 pt-5 mr-5 text-white font-weight-regular"
      style="border-radius: 30px; font-size: 1.35em"
    >
      {{ getTextBubbleContent }}
    </v-card>

    <morphedButtonVue></morphedButtonVue>
  </div>
</template>




<script>
import { mapGetters } from "vuex";
import morphedButtonVue from "./morphedButton.vue";

export default {
  name: "TextBubble",
  components: {
    morphedButtonVue,
  },
  computed: {
    ...mapGetters(["getTextBubbleContent", "getAvatarStatus"]),
  },
};
</script>