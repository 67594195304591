export default {
    state: {
        speechRecognitionObj: null,
        finalTranscript: '',
        intermTranscript: '',
        autoSpeechEndDelay: 800, // in milliseconds
        autoSpeechEndTimeout: null,


    },
    getters: {

    },
    mutations: {

    },
    actions: {
        startSpeechRecognition({ state, dispatch }) {
            try {
                state.speechRecognitionObj.stop();
                state.speechRecognitionObj.start();
                console.log(`-> Speech Rec:: START REQUESTED`)
            } catch (error) {
                console.error(`startSpeechRecognition() :: Error ${error}`);
                // disable listening animation     --- this is common                                
            }
            finally {
                dispatch('avatarStopSpeaking')
            }
        }, // End startSpeechRecognition()

        abortSpeechRecognition({ state }) {
            try {
                state.speechRecognitionObj.abort();
                console.log(`-> Speech Rec:: ABORT REQUESTED`)
            } catch (error) {
                console.error(`abortSpeechRecognition() :: Error ${error}`);
            }
            finally {
                // not sure what goes here                
            }
        }, // End abortSpeechRecognition()

        stopSpeechRecognition({ state }) {
            try {
                state.speechRecognitionObj.stop()
                console.log(`-> Speech Rec:: STOP REQUESTED`)
            } catch (error) {
                console.error(`stopSpeechRecognition() :: Error ${error}`);
            }
            finally {
                // not sure what goes here  
            }

        }, // End stopSpeechRecognition()



        processSpeechRecognition({ state, commit, dispatch, getters }) {
            try {
                console.log(`--> Processing speech recognition`);
                //--------------------------OnResult()-------------------------------------
                state.speechRecognitionObj.onresult = (event) => {
                    // do something       
                    console.log(`-> Speech Rec (event):: RESULT`)
                    // show intermediate transcript
                    state.intermTranscript = event.results[event.results.length - 1][0].transcript
                    commit('setTextBubbleContent', state.intermTranscript)
                    clearTimeout(state.autoSpeechEndTimeout)
                    // check if the transctipt is final
                    if (event.results[event.resultIndex].isFinal) {
                        state.finalTranscript = state.finalTranscript + state.intermTranscript;
                        state.intermTranscript = state.finalTranscript;
                        state.autoSpeechEndTimeout = setTimeout(() => {
                            dispatch('stopSpeechRecognition')
                            // if transcript is final read the transcript and generate answer
                            dispatch('generateAnswer', state.finalTranscript)
                                .then(result => {
                                    state.finalTranscript = ''
                                    if (result !== null) {
                                        dispatch('renderResponse', result)
                                    }
                                })
                            commit('setTextBubbleContent', state.finalTranscript)
                        }, state.autoSpeechEndDelay)
                    }

                    // handle error will reset the flags --- this is common


                } // onresult()             

                //--------------------------OnStart()-------------------------------------
                state.speechRecognitionObj.onstart = () => {
                    // do something
                    console.log(`-> Speech Rec (event):: STARTED`)
                    // show listening animation
                    commit('setTextBubbleContent', 'I am listening..')
                    commit('setAvatarStatus', 'listening') // set Avatar Status LISTENING

                    // disable any avatar speech
                    // disable any video playback

                } // onstart()                    

                //--------------------------OnEnd()-------------------------------------
                state.speechRecognitionObj.onend = () => {
                    // do something
                    console.log(`-> Speech Rec (event):: ENDED`)
                    // hide listening animation
                    if (getters.getAvatarStatus !== 'thinking') {
                        commit('setAvatarStatus', 'idle') // set Avatar Status IDLE
                    }
                    // disable listening animation     --- this is common


                } // onend()

                //--------------------------OnError()-------------------------------------
                state.speechRecognitionObj.onerror = (event) => {
                    // do something
                    console.log(`-> Speech Rec (event):: ERROR`)

                    // handle error will reset the flags --- this is common
                } // onerror()

                //--------------------------OnSoundEnd()-------------------------------------
                state.speechRecognitionObj.onsoundend = (event) => {
                    // do something
                    console.log(`-> Speech Rec (event):: SOUND ENDED`)

                    // handle error will reset the flags --- this is common
                } // onsoundend()

                //--------------------------OnSoundStart()-------------------------------------
                state.speechRecognitionObj.onsoundstart = (event) => {
                    // do something
                    console.log(`-> Speech Rec (event):: SOUND STARTED`)

                    // handle error will reset the flags --- this is common
                } // onsoundstart()

            } catch (error) {
                console.error(`processSpeechRecognition(): ${error}`)

                // handle error will reset the flags --- this is common
            }
        }, // End processSpeechRecognition()

        setupSpeechRec({ state, dispatch }) {
            try {
                console.log(`--> Setting up speech recognition`);
                const grammer = `#JSGF V1.0; grammar nouns; public <noun> = venduni | happy | wall;`;
                const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
                const speechGrammer = window.SpeechGrammarList || window.webkitSpeechGrammarList;
                const speechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
                state.speechRecognitionObj = new speechRecognition();
                const speechRecognitionList = new speechGrammer();
                speechRecognitionList.addFromString(grammer, 1);
                state.speechRecognitionObj.grammers = speechRecognitionList;
                state.speechRecognitionObj.lang = "en-US";
                state.speechRecognitionObj.continuous = true;
                state.speechRecognitionObj.interimResults = true;
                dispatch('processSpeechRecognition')
            } catch (error) {
                console.error(`setupSpeechRec(): ${error}`)
            }
        }, // End setupSpeechRec()

    }
}