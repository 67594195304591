import axios from "axios"

export default {
  state: {
    convHistory: '',
    sessionID: Math.random(),
    speechRecognition: null,
    speechStatus: null,
    onResult: null,
    speakStatus: "Tap To Talk",
    isListening: false,
    responseTime: null,
  },
  getters: {
    getResultStatus: state => state.onResult,
    getSpeakStatus: state => state.speakStatus,
    getIsListening: state => state.isListening,
    getResponseTime: state => state.responseTime
  },
  mutations: {
    setSpeakStatus(state, status) {
      state.speakStatus = status;
    },
    setIsListening(state, status) {
      state.isListening = status
    },
    resetConvHistory(state){
      state.convHistory = ''
    }
  },
  actions: {    
    async generateAnswer({ state, commit, getters}, userInput) {
      try {
        console.log(`USER INPUT: ${userInput}, Conv History: ${state.convHistory}`)
        commit('setAvatarStatus', 'thinking') // set Avatar Status THINKING
        const startTime = performance.now();
        let endTime = null;
        var con = JSON.stringify({
          "conversation_history": state.convHistory,
          "question": userInput
        });

        var config = {
          method: 'post',
          url: 'https://zbgvhwwampcbxveohmjj.supabase.co/functions/v1/arsai/generate-response',
          //  url: 'http://127.0.0.1:54321/functions/v1/arsai/generate-response',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpiZ3Zod3dhbXBjYnh2ZW9obWpqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE5NzM0MjMsImV4cCI6MjAxNzU0OTQyM30.v-LU012HGCtLNve0ul6FXmowNquaJt8rBfdkI-wk8cg"
            // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0"

          },
          data: con
        };
        
        const { data } = await axios(config)
        console.log(data)
        endTime = performance.now();        
        state.responseTime = endTime - startTime
        if(getters.getAvatarStatus !== 'thinking') return null
        state.convHistory = state.convHistory + " Human: " + userInput
        const assist = data.answer.content
        state.convHistory = state.convHistory + " Aissistant: " +  JSON.parse(assist).response + " \n"
        console.log(state.convHistory)
        
        return (data.answer.content)

      } catch (error) {
        console.log(error)
        return error
      }
    },
  }
}