<template>
  <div>
    <v-app-bar app color="primary">
      <v-app-bar-title >
        <v-img
          contain
          height="5vh"
          class="my-auto"
          max-width="11vw"          
          src="@/assets/dashboardLogo.png"
        ></v-img>        
      </v-app-bar-title>      
      <template v-slot:append>
        <ThemeToggleButtonVue />
      </template>      
    </v-app-bar>    
    <NavigationPanelCompVue />        
  </div>
</template>

<script>
import ThemeToggleButtonVue from "@/components/ThemeToggleButton.vue";
import NavigationPanelCompVue from "./NavigationPanelComp.vue";

export default {
  name: "NavigationComp",
  components: {
    ThemeToggleButtonVue,
    NavigationPanelCompVue,
  },
  data(){
    return{        

    }
  }
};
</script>