<template>
<div>
  <KioskHeaderVue></KioskHeaderVue>  
  <v-container fluid class="fill-height justify-center pa-0 ma-0">
    <faceDetectionVue :display="false" :enable="true" :timeout="60000" :refreshRate="1000"></faceDetectionVue>
    <v-row class="fill-height" no-gutters align="end">
      <v-col cols="7" class="">
        <AvatarCompVue></AvatarCompVue>        
      </v-col>
      <v-col cols="5" align-self="center" align="center">
        <TextBubbleVue></TextBubbleVue>        
      </v-col>
    </v-row>
  </v-container>
  <KioskFooterVue></KioskFooterVue>
  <VideoViewerCompVue></VideoViewerCompVue>
  <ImageViewerCompVue></ImageViewerCompVue>

</div>
  
</template>

<script>
import AvatarCompVue from '@/components/kioskViewComponents/AvatarComp.vue';
import TextBubbleVue from '@/components/kioskViewComponents/TextBubble.vue';
import faceDetectionVue from '@/components/kioskViewComponents/faceDetection.vue';
import KioskHeaderVue from '@/components/kioskViewComponents/KioskHeader.vue';
import KioskFooterVue from '@/components/kioskViewComponents/KioskFooter.vue';
import VideoViewerCompVue from '@/components/kioskViewComponents/VideoViewerComp.vue';
import ImageViewerCompVue from '@/components/kioskViewComponents/ImageViewerComp.vue';


export default {
  name: "KioskView",

  components: {
    AvatarCompVue,
    TextBubbleVue,
    faceDetectionVue,
    KioskHeaderVue,
    KioskFooterVue,
    VideoViewerCompVue,
    ImageViewerCompVue,    
  },
  methods: {
    
  },
  mounted(){
    let defaultWelcomeSpeech= `Hola! I am Nuni, a La Duni Ambassador, I am here to help you enjoy your Venduni visit.
    Please ask me anything you want to know about Venduni or the Happy Wall.`
    this.$store.commit('setTextBubbleContent', defaultWelcomeSpeech);
    this.$store.commit('getDefaultAvatarGreetingText', defaultWelcomeSpeech);
    this.$store.dispatch('setupSpeechRec');
  }
};
</script>
