<template>
  <div :hidden="!display">
    <video ref="vid" playsinline style="position: absolute; top: 0; left: 0; "></video>
    <canvas ref="canv" style="position: absolute; top: 0; left: 0"></canvas>
    <v-container fluid>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Values</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Face Distance</td>
            <td>{{ faceDistance }}</td>
          </tr>
          <tr>
            <td>inBetween eyes Distance</td>
            <td>{{ inBetweenEyesDistance }}</td>
          </tr>
          <tr>
            <td>Right Eye Angle</td>
            <td>{{ eyeR }}°</td>
          </tr>
          <tr>
            <td>Left Eye Angle</td>
            <td>{{ eyeL }}°</td>
          </tr>
          <tr>
            <td>Nose Angle</td>
            <td>{{ nose }}°</td>
          </tr>
          <tr>
            <td>
              <span v-if="isFaceDetected" class="green--text "
                >Face Detected</span
              >
            </td>
          
            <td>
              <span                
                class="green--text "
                v-if="isLooking"
              >
                Looking</span
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const blazeface = require("@tensorflow-models/blazeface");
const tf = require("@tensorflow/tfjs");

export default {
  name: "faceDetection",
  props: {
    enable: Boolean,
    display: Boolean,
    timeout: Number,
    refreshRate: Number
  },
  data: () => ({
    isFaceDetected: false,    
    isLooking: false,
    timesCount: 0,
    video: null,
    canvas: null,
    ctx: null,
    returnTensors: false,
    flipHorizontal: true,
    annotateBoxes: true,
    faceDistance: null,
    inBetweenEyesDistance: null,
    eyeR: null,
    eyeL: null,
    nose: null,
    spRecObj: null,
    faceRefreshFlag: false,
    faceRefreshTimer: null,
  }),
  methods: {
    initiate() {
      this.video = this.$refs.vid;
      this.canvas = this.$refs.canv;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,            
            video: { width: 500, height: 500, frameRate: { max: 10 } },
          })
          .then((stream) => {
            this.video.srcObject = stream;
            this.video.play().then(() => {
              this.video.width = this.video.videoWidth;
              this.video.height = this.video.videoHeight;
              this.canvas.width = this.video.videoWidth;
              this.canvas.height = this.video.videoHeight;
              this.ctx = this.canvas.getContext('2d');
              this.ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
              // this.initSpeechRec();
              console.log("video loaded --> setting backend");
              tf.setBackend("webgl").then(() => {
                console.log("backend set --> loading model");
                blazeface.load().then((model) => {
                  console.log("model loaded --> getting predictions"); 
                  if(this.enable){
                    setInterval(() => {
                      this.renderPredictions(model);
                    }, this.refreshRate);                  
                  }                                    
                });
              });
            });
          });
      }
    },
    renderPredictions(model) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // this.faceDistance = 0;
      // this.inBetweenEyesDistance = 0;
      // this.eyeR = 0;
      // this.eyeL = 0;
      // this.nose = 0;
      model
        .estimateFaces(
          this.video,
          this.returnTensors,
          this.flipHorizontal,
          this.annotateBoxes
        )
        .then((predictions) => {
          if (predictions.length > 0) {
            for (let i = 0; i < predictions.length; i++) {
              const start = predictions[i].topLeft;
              const end = predictions[i].bottomRight;
              // const confidence = predictions[i].probability;
              const size = [end[0] - start[0], end[1] - start[1]];
              this.faceDistance = (((start[0] - end[0]) / 500) * 100).toFixed(
                2
              );
              // console.log('faceDistance:', this.faceDistance);

              if(this.faceDistance >= 17){
                this.timesCount = this.timesCount + 1;
              }

              if (this.timesCount >= 3) {
                this.isFaceDetected = true;
                this.timesCount = 0;
                if (this.annotateBoxes) {
                  const landmarks = predictions[i].landmarks;
                  this.inBetweenEyesDistance = (
                    ((landmarks[0][0] - landmarks[1][0]) / 300) *
                    100
                  ).toFixed(2);
                  // console.log('eyeDistance', this.inBetweenEyesDistance);
                  this.findEyeNoseAngle(
                    landmarks[0],
                    landmarks[1],
                    landmarks[2]
                  );
                  this.ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
                  this.ctx.fillRect(start[0], start[1], size[0], size[1]);
                  this.ctx.fillStyle = "blue";
                  for (let j = 0; j < landmarks.length; j++) {
                      const x = landmarks[j][0];
                      const y = landmarks[j][1];
                      this.ctx.fillRect(x, y, 5, 5);
                  }
                  this.eyeL <= 54 &&         //ORIGINAL VALUES FOR STRAIGHT CAMERA
                    this.eyeL > 0 &&
                    this.eyeR <= 54 &&
                    this.eyeR > 0
                // console.log(this.eyeR)
                  if (
                    this.eyeL <= 50 &&
                    this.eyeL > 0 &&
                    this.eyeR <= 60 &&
                    this.eyeR > 0
                  ) {                   
                    this.Looking()
                    this.isLooking = true;
                  }
                  else{
                    this.isLooking = false
                  }
                  
                }
              }
              else{
                this.isFaceDetected = false;
              }
            }
          }
          else{
            this.isFaceDetected = false;
            this.isLooking = false;
          }
         
        });
    },
    findEyeNoseAngle(eyeR, eyeL, nose) {
      const a = parseFloat(
        (
          Math.pow(eyeL[0] - nose[0], 2) + Math.pow(eyeL[1] - nose[1], 2)
        ).toFixed(4)
      );
      const b = parseFloat(
        (
          Math.pow(eyeR[0] - nose[0], 2) + Math.pow(eyeR[1] - nose[1], 2)
        ).toFixed(4)
      );
      const c = parseFloat(
        (
          Math.pow(eyeR[0] - eyeL[0], 2) + Math.pow(eyeR[1] - eyeL[1], 2)
        ).toFixed(4)
      );
      const cosA = parseFloat(
        ((b + c - a) / (2 * Math.sqrt(b) * Math.sqrt(c))).toFixed(4)
      );
      const cosB = parseFloat(
        ((a + c - b) / (2 * Math.sqrt(c) * Math.sqrt(a))).toFixed(4)
      );
      const cosC = parseFloat(
        ((a + b - c) / (2 * Math.sqrt(a) * Math.sqrt(b))).toFixed(4)
      );
      this.eyeR = (Math.cos(cosA) * (180 / Math.PI)).toFixed(2);
      this.eyeL = (Math.cos(cosB) * (180 / Math.PI)).toFixed(2);
      this.nose = (Math.cos(cosC) * (180 / Math.PI)).toFixed(2);
      // console.log('Angles btw eye\'s and nose: eyeR: ' +this.eyeR+ ' eyeL: '+ this.eyeL + ' Nose: '+ this.nose);
    },
    Looking() {
      // console.log("Looking");            
      clearTimeout(this.faceRefreshTimer);
      if (!this.faceRefreshFlag) {
        // The Avatar will greet a user here.
        if(this.getAvatarStatus === 'idle'){
          this.$store.dispatch('avatarSpeak', this.getDefaultAvatarGreetingText)
        }
        
        //------------------------------  
        this.faceRefreshFlag = true;
        // console.log("Face Refresh Flag:", this.faceRefreshFlag);
      }
      this.faceRefreshTimer = setTimeout(() => {
        // Everyting will reset here
        this.isLooking = false;        
        this.faceRefreshFlag = false;
        // console.log("Face Refresh Flag:", this.faceRefreshFlag);
        this.$store.dispatch('resetKiosk')
        
        // window.clientApp.avatar.showResponse(
        //   "Hi, I’m Sophia part of Informa Markets CST Summit Retreat Team."
        // );
        // this.$store.commit("setShowWelcomeDialog", false);
        // this.$store.commit("setSessionObj", {});
      }, this.timeout);
    },
  },
  computed:{
    ...mapGetters(['getDefaultAvatarGreetingText', 'getAvatarStatus'])

  },
  mounted() {
    this.initiate();
  },
};
</script>

<style>
</style>