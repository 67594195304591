<template>
  <div>
    <v-card tile elevation="0" color="cardBackground">
      <v-layout>
        <v-toolbar flat color="grey-lighten-2">
          <v-sheet width="20vw" color="transparent" class="mx-3">
            <v-text-field
              placeholder="Search"
              hide-details
              density="compact"
              variant="outlined"
              append-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-sheet>

          <template v-slot:append>
            <v-btn
              icon="mdi-sync"
              @click="$store.dispatch('refreshFileList')"
              variant="plain"
              :ripple="false"
            ></v-btn>
            <input
              type="file"
              ref="fileInput"
              multiple
              accept="image/*,audio/*,video/*"
              style="display: none"
              @change="handleFileChange"
            />
            <v-btn
              border
              color="primary"
              prepend-icon="mdi-upload"
              class="text-none mx-1"
              variant="flat"
              @click="openFileSelector"
              >Upload</v-btn
            >
            <v-btn
              border
              v-if="selected.length > 0"
              color="red-darken-1"
              prepend-icon="mdi-delete"
              class="text-none mx-1"
              variant="flat"
              @click="deleteSelectedFiles"
              >Delete {{ selected.length }}</v-btn
            >
          </template>
        </v-toolbar>
      </v-layout>
    </v-card>
    <v-row>
      <v-col>
        <div
          v-if="getFilesObjArr.length === 0"
          class="d-flex justify-center my-10"
        >
          Nothing to show. Click on + Add Q&A get started.
        </div>
        <v-card tile elevation="0" color="red" width="100%">
          <v-data-table
            v-model="selected"
            :headers="headerObj"
            :items="getFilesObjArr"
            item-value="name"
            show-select
            items-per-page="10"
            item-selectable="Name"
            @click:row="onRowClick"
          >
            <template v-slot:[`item.size`]="{ item }">
              {{ readableFileSize(item.metadata.size) }}
            </template>
            <template v-slot:[`item.url`]="{ item }">
              <v-tooltip text="Copy URL">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" @click="copyUrl(item)"
                    >mdi-content-copy</v-icon
                  >
                </template>
              </v-tooltip>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{ item.metadata.mimetype }}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ formatTime(item.updated_at) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="showPreview">
        <filePreviewCompVue
          :src="filePreviewSrc"
          :fileObj="filePreviewObj"
          @close-preview="showPreview = !showPreview"
        ></filePreviewCompVue>
      </v-col>
    </v-row>
    <fileUploadDialogVue></fileUploadDialogVue>
  </div>
</template>

<script>
import moment from "moment-timezone";
import formatbytes from "ars-formatbytes";
import { mapGetters } from "vuex";
import filePreviewCompVue from "@/components/fileViewComponents/filePreviewComp.vue";
import fileUploadDialogVue from "@/components/fileViewComponents/fileUploadDialog.vue";

export default {
  name: "FilesView",
  components: {
    filePreviewCompVue,
    fileUploadDialogVue,
  },
  data() {
    return {
      showPreview: false,
      filePreviewSrc: null,
      filePreviewObj: null,
      selected: [],
      headerObj: [
        { title: "Name", key: "name" },
        { title: "Public URL", key: "url" },
        { title: "Size", key: "size" },
        { title: "Type", key: "type" },
        { title: "Last Updated", key: "updated_at" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getFilesObjArr"]),
  },
  methods: {
    deleteSelectedFiles() {
      if (this.selected.length > 0) {
        console.log(this.selected);
        this.$store.dispatch("deleteFiles", this.selected).then(() => {
          this.selected = [];
        });
      }
    },
    openFileSelector() {
      this.$refs.fileInput.click(); // Triggers the file input click
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);
      if (files.length > 0) this.$store.dispatch("uploadFiles", files);
    },
    formatTime(timestamp) {
      return moment(timestamp)
        .tz("America/New_York")
        .format("MM/DD/YYYY, hh:mm:ss A");
    },
    readableFileSize(size) {
      return formatbytes(size);
    },
    onLoad({ done }) {
      done("empty");
    },
    copyUrl(item){
      console.log("COPU URL:", item.name)
      this.$store
        .dispatch("generateSignedURL", item.name)
        .then(async (result) => {
          await navigator.clipboard.writeText(result)
        });
    },
    onRowClick(click, item) {
      console.log("Row clicked:", item.item.name);
      this.$store
        .dispatch("generateSignedURL", item.item.name)
        .then((result) => {
          this.filePreviewSrc = result;
          this.showPreview = true;
          this.filePreviewObj = item.item;
        });
    },
  },
  mounted() {
    if (this.getFilesObjArr.length === 0) {
      this.$store.dispatch("fetchAllFiles");
    }
  },
};
</script>