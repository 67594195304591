<template>
  <v-dialog
    
    v-model="getIsFileUploading"
    width="25%"
    style="z-index: 9999"
    persistent
  >
    <v-card class="d-flex justify-center align-center px-3 pb-3">
      <v-card-title class="">Uploading</v-card-title>
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
      class="my-4"
    ></v-progress-circular>
    <v-divider></v-divider>
    <v-card-text> Keep the browser open for upload to complete.</v-card-text>     
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "fileUploadDialog",
  data() {
    return {
      showDialog: true,
    };
  },
  computed:{
    ...mapGetters(['getIsFileUploading'])
  }
};
</script>

<style  >
.v-overlay__scrim {
  opacity: 0.4;
}
</style>
